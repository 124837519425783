import React, { useState, useEffect, useRef, useCallback } from 'react';
import Icons from '../../../../Common/IconsComponent';
import { AnimatePresence, motion } from 'framer-motion';
import { usePopup } from '../../../../Popups/PopupContext';
import { useUpdateProjBalanceMutation } from '../../../../../services/phpService';
import { useParams, useNavigate } from 'react-router-dom';
import useBoost from '../../../../../helpers/clickerBoostHelper';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';
import boostIcon from '../../../../../assets/img/shitBoost.webp';
import paperPlane from '../../../../../assets/img/paperPlane.webp';
import ProjectTasks from './ProjectTasks';
import NavigationWidget from '../../NavigationWidget';
import ClickerLeaders from './ClickerLeaders';
import Preloader from '../../Preloader/Preloader.jsx';
import MyButton from '../../../../Common/styles/MyButton.jsx';
import MyBalance from '../../../../Common/styles/MyBalance';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';
import durovAnimation from '../../../../../assets/animations/durov.riv'; // id: 29
import trumpHero from '../../../../../assets/animations/trump.riv';
import czAnimation from '../../../../../assets/animations/cz.riv'; // id: 59
import { useEventWinnerPopup } from '../../../../../helpers/eventNotify';
import './Project.scss';

export default function ProjectClicker() {
	const { genHash, addNotification, user, showPopup } = usePopup();
	useEventWinnerPopup(user, showPopup);
	const [updateProjBalance] = useUpdateProjBalanceMutation();
	const [currCoins, setCurrCoins] = useState(0);
	const [gamePaused, setGamePaused] = useState(false);
	const [timeRemaining, setTimeRemaining] = useState(null);
	const [isAnimationActive, setIsAnimationActive] = useState(false);
	const [animations, setAnimations] = useState([]);
	const accumulatedCoinsRef = useRef(0);
	const [totalPoints, setTotalPoints] = useState(null);
	const [activeTasksCount, setActiveTasksCount] = useState(0);
	const [canPlayAt, setCanPlayAt] = useState(10);
	const [isLoading, setIsLoading] = useState(true);
	const [webpImage, setWebpImage] = useState(null);
	const { projectId: projectIdParam } = useParams();
	const navigate = useNavigate();

	const [energyVal, setEnergyVal] = useState(user?.tap_value ?? 1); // user?.tap_value ?? 1
	const [clickNewCoins, setClickNewCoins] = useState(user?.tap_value ?? 1); // user?.tap_value ?? 1
	const [unsubmittedCoins, setUnsubmittedCoins] = useState(0);

	const { visible, position, activateBoost } = useBoost(gamePaused);

	const projectId = Number(projectIdParam);
	const secretURL = process.env.REACT_APP_SECRET_URL;

	// ANIMATION ID for project clicker rive animation hero: 77 - trump, 29 - durov, 59 - cz
	const animationSrc =
		projectId === 77 ? trumpHero : null;

	const { RiveComponent, rive } = useRive(
		{
			src: animationSrc,
			stateMachines: 'State Machine 1',
			autoplay: gamePaused ? false : true,
		},
		{ enabled: Boolean(animationSrc) }
	);
	const tapInput = useStateMachineInput(rive, 'State Machine 1', 'tap');
	const boostInput = useStateMachineInput(rive, 'State Machine 1', 'boost');

	const navBack = useCallback(
		() => navigate(`/projects/${projectId}`),
		[navigate, projectId]
	);

	useEffect(() => {
		if (!user) {
			navigate('/');
			return;
		}
		const timer = setTimeout(() => setIsLoading(false), 500);
		return () => clearTimeout(timer);
	}, [user, navigate]);

	useEffect(() => {
		if (user?.projects_votes_count) {
			setTotalPoints(user.projects_votes_count[String(projectId)]);
		}
	}, [projectId, user]);

	useEffect(() => {
		if (user?.projects_gaming) {
			const matchingProject = user?.projects_gaming.find(
				(project) => Number(project.project_id) === projectId
			);
			if (matchingProject) {
				setTimeout(() => {
					setCurrCoins(matchingProject.energy);
				}, 200);
			}
		}
	}, []);

	useEffect(() => {
		const matchingProject = user?.projects_gaming?.find(
			(project) => Number(project.project_id) === projectId
		);
		if (matchingProject) {
			setTimeout(() => {
				setCanPlayAt(matchingProject.can_play_at);
			}, 200);
		}
	}, [projectId, user]);

	useEffect(() => {
		try {
			const parsedProjects = JSON.parse(localStorage.getItem('projectsData')) || [];
			const project = parsedProjects.find((p) => p.id === projectId);
			if (project) {
				setWebpImage(project.webpImage || null);
				setActiveTasksCount(project.active_tasks?.length || 0);
			}
		} catch (error) {
			console.error('Error parsing JSON:', error);
		}
	}, [projectId]);

	useEffect(() => {
		const currentTimeStamp = moment.tz('UTC').unix();
		const remainingTime = canPlayAt - currentTimeStamp;

		if (remainingTime <= 0) {
			setGamePaused(false);
			setTimeRemaining(null);
			return;
		}

		const updateGameStatus = () => {
			const currentTimeStamp = moment.tz('UTC').unix();
			const remainingTime = canPlayAt - currentTimeStamp;

			if (remainingTime <= 0) {
				setGamePaused(false);
				setTimeRemaining(null);
				clearInterval(timer);
			} else {
				setGamePaused(true);
				setTimeRemaining(remainingTime);
			}
		};

		const timer = setInterval(updateGameStatus, 1000);

		return () => clearInterval(timer);
	}, [canPlayAt]);

	const boostClickedHandler = () => {
		activateBoost(energyVal, clickNewCoins, setEnergyVal, setClickNewCoins, user);

		if (boostInput) {
			boostInput.value = true;
			setTimeout(() => {
				boostInput.value = false;
			}, 10000);
		}
	};

	const submitData = useCallback(
		async (coins) => {
			const cappedCoins = Math.min(coins, 1000);
			try {
				await updateProjBalance({
					token: await genHash(),
					id_telegram: user?.id_telegram,
					id_project: projectId,
					score: cappedCoins,
				}).unwrap();
			} catch (e) {
				setUnsubmittedCoins((prev) => prev + cappedCoins);
			}
		},
		[genHash, projectId, updateProjBalance, user?.id_telegram]
	);

	const updateBalance = useCallback(() => {
		const coinsToSubmit = accumulatedCoinsRef.current + unsubmittedCoins;
		submitData(coinsToSubmit);
		accumulatedCoinsRef.current = 0;
		setUnsubmittedCoins(0);
	}, [submitData, unsubmittedCoins]);

	const debouncedUpdateBalanceRef = useRef(debounce(updateBalance, 1200));

	const pauseGame = useCallback(async () => {
		const futureTimestamp = Math.floor(Date.now() / 1000) + 10;
		try {
			await fetch(secretURL + '/api/set-project-activity', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					token: await genHash(),
					id_telegram: user?.id_telegram,
					id_project: projectId,
					timestamp: futureTimestamp,
				}),
			});
			addNotification('success', 'Good job! You just voted for free');
			setCurrCoins(0);
		} catch (error) {
			addNotification('error', 'Your vote was not counted. Please try again.');
		}
	}, [genHash, projectId, secretURL, user?.id_telegram]);

	useEffect(() => {
		let pauseTimeoutId;

		if (currCoins >= (user?.max_energy ?? 1000)) {
			setGamePaused(true);

			pauseTimeoutId = setTimeout(() => {
				pauseGame();
			}, 1500);
		}

		return () => clearTimeout(pauseTimeoutId);
	}, [currCoins]);

	const handleShowAnimation = useCallback((event) => {
		if (!event) return;

		const touch = event.touches ? event.touches[0] : event;
		const x = touch.pageX;
		const y = touch.pageY;

		const id = Date.now();
		setAnimations((prev) => [...prev, { id, x, y }]);
		setIsAnimationActive(true);
	}, []);

	const handleTouchStart = (event) => handleShowAnimation(event);

	const handleTouchEnd = () => {
		const clickValue = clickNewCoins;
		setCurrCoins((prev) => Math.min(prev + clickValue, 1000));
		accumulatedCoinsRef.current += clickValue;

		if (!gamePaused && tapInput) {
			tapInput.fire();
		}

		debouncedUpdateBalanceRef.current();
	};

	const calculateStrokeDasharray = (currCoins) => {
		const circleCircumference = 2 * Math.PI * 45;
		const percentage = (currCoins / 1000) * circleCircumference;
		return `${percentage} ${circleCircumference}`;
	};

	const handleShowLeaders = () => showPopup(<ClickerLeaders projectId={projectId} />);
	const handleMakeClick = () => showPopup(<ProjectTasks projectId={projectId} />);

	const removeAnimation = useCallback((id) => {
		setAnimations((prev) => prev.filter((anim) => anim.id !== id));
	}, []);

	const isSmallScreen = window.innerHeight <= 685;

	const riveComponentStyle = {
		maxHeight: isSmallScreen ? '205px' : '275px',
		maxWidth: isSmallScreen ? '205px' : '275px',
		display: 'flex',
		alignSelf: 'center',
	};

	return (
		<>
			<div className='gameWrapper'>
				{isLoading ? (
					<Preloader />
				) : (
					<>
						<MyButton
							variant='smallIcon'
							style={{ top: '20px', left: '20px' }}
							onClick={navBack}
						>
							<Icons.Larrow />
						</MyButton>
						<MyButton
							variant='smallIcon'
							style={{ top: '20px', right: '20px' }}
							onClick={handleShowLeaders}
						>
							<Icons.CupWhite />
						</MyButton>
						<MyBalance>
							<span>Your votes:</span>
							<div style={{ marginBottom: '0' }}>
								{totalPoints ?? 0}
								<Icons.Shit />
							</div>
						</MyBalance>
						{!gamePaused && visible && (
							<motion.div
								initial={{ y: 7, rotate: 0, opacity: 1 }}
								animate={{ y: [5, 15, 20], x: [-15, 15, 5] }}
								transition={{
									duration: 4,
									repeat: Infinity,
									repeatType: 'mirror',
									ease: 'easeInOut',
								}}
								style={{ position: 'absolute', top: '50%', left: 0, zIndex: 150 }}
							>
								<motion.div
									animate={{ opacity: [0, 1] }}
									transition={{
										duration: 4,
										repeat: Infinity,
										repeatType: 'mirror',
										ease: 'easeInOut',
									}}
								>
									<div
										className='boost-element'
										style={{
											position: 'absolute',
											overflow: 'hidden',
											left: `${position.x}px`,
											top: `${position.y}px`,
											cursor: 'pointer',
											width: '100px',
											height: '100px',
											zIndex: 25,
										}}
										onClick={boostClickedHandler}
									>
										<motion.img
											src={boostIcon}
											alt='Boost'
											style={{ width: '100%', height: '90%', userSelect: 'none' }}
											initial={{ opacity: 0, rotate: 0 }}
											animate={{ opacity: 1, rotate: 0 }}
											transition={{
												duration: 4,
												repeat: Infinity,
												repeatType: 'mirror',
												ease: 'easeInOut',
											}}
										/>
									</div>
								</motion.div>
							</motion.div>
						)}
						<div
							className='clicker__clickArea'
							onTouchStart={!gamePaused ? handleTouchStart : undefined}
							onTouchEnd={!gamePaused ? handleTouchEnd : undefined}
						>
							<AnimatePresence>
								{isAnimationActive &&
									animations.map((anim) => (
										<motion.div
											key={anim.id}
											className='clickerAnimation'
											initial={{ opacity: 1, y: 0 }}
											animate={{
												opacity: [1, 0],
												x: [-20, -20],
												y: window.innerHeight <= 685 ? [-185, -280] : [-260, -350],
											}}
											exit={{ opacity: 0 }}
											transition={{ duration: 0.5 }}
											style={{
												color: '#000',
												fontSize: '52px',
												left: `${anim.x}px`,
												top: `${anim.y}px`,
												position: 'absolute',
												color: '#333333',
												zIndex: 10,
											}}
											onAnimationComplete={() => removeAnimation(anim.id)}
										>
											{projectId === 29 ? (
												<div className='clicker__clickValue'>
													<img src={paperPlane} alt='Paper Plane' />
												</div>
											) : (
												<div className='clicker__clickValue'>+{clickNewCoins}</div>
											)}
										</motion.div>
									))}
							</AnimatePresence>
							<div className='clicker__clickerHero'>
								{animationSrc ? (
									<RiveComponent style={riveComponentStyle} />
								) : (
									<picture>
										<img src={webpImage} alt='Image' />
									</picture>
								)}
							</div>
							<div className='clicker__progressBox'>
								<div className='clicker__progressBar'>
									<svg
										viewBox='0 0 100 100'
										style={{
											position: 'absolute',
											width: '100%',
											height: '100%',
											borderRadius: '100%',
										}}
									>
										<defs>
											<filter id='boxShadow' x='-20%' y='-20%' width='140%' height='140%'>
												<feGaussianBlur in='SourceAlpha' stdDeviation='3' />
												<feOffset dx='0' dy='0' result='offsetblur' />
												<feComponentTransfer>
													<feFuncA type='linear' slope='0.5' />
												</feComponentTransfer>
												<feMerge>
													<feMergeNode />
													<feMergeNode in='SourceGraphic' />
												</feMerge>
											</filter>
										</defs>

										<circle
											cx='50'
											cy='50'
											r='45'
											fill='none'
											stroke='#EEE'
											strokeWidth='2.5'
										></circle>
										{currCoins > 0 && (
											<circle
												cx='50'
												cy='50'
												r='45'
												fill='none'
												strokeWidth='2.5'
												strokeLinecap='round'
												strokeDasharray={calculateStrokeDasharray(currCoins)}
												style={{ transition: 'stroke-dasharray 0.35s' }}
												stroke='#000'
											></circle>
										)}
									</svg>
								</div>
							</div>
						</div>
						<div className='clicker__session'>
							<div className='clicker__sessionText'>
								{gamePaused ? (
									<>
										{timeRemaining ? (
											<>
												<Icons.clockIcon />
												<span style={{ marginLeft: '5px' }}>Next Tap session in:</span>
											</>
										) : (
											<span style={{ marginLeft: '5px' }}>
												Calculating time for next session...
											</span>
										)}
									</>
								) : (
									<span style={{ marginLeft: '5px' }}>Tap to vote:</span>
								)}
							</div>
							<div className='clicker__sessionProgress'>
								{gamePaused
									? timeRemaining || null
									: `${currCoins}/${user?.max_energy ?? 1000}`}
							</div>
						</div>
						{activeTasksCount > 0 && (
							<MyButton variant='black' onClick={handleMakeClick} className='mainBtn'>
								tasks <span style={{ color: 'black' }}>{activeTasksCount}</span>
							</MyButton>
						)}
					</>
				)}
			</div>
			<NavigationWidget />
		</>
	);
}
